import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

export default function RewardsLoadedView({
  pointsMeterWidth,
  RewardImagesElement,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  minimumSubTotal,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) {
  const { themeImages } = useContext(ThemeContext)
  return (
    <div
      className={css(classes.rewardContainer, {
        background: `url(${themeImages.rewardBG}) no-repeat`,
        backgroundSize: '100% 100%',
      })}
    >
      <FView style={{ transform: 'translateY(-15px)' }}>
        {RewardImagesElement}
        {LeftArrowButtonElement}
        {RightArrowButtonElement}
      </FView>
      <div className={classes.meter}>
        <div
          className={classes.progress}
          style={{
            width: pointsMeterWidth * 340,
            transition: 'width 2s',
          }}
        >
          <img src={themeImages.pointsMeter} alt="Meter" />
        </div>
      </div>
      <FView className={classes.pointsText} alignCenter w={'8.5rem'}>
        <FText h8 black>
          {points}/{maxPoints}
        </FText>
      </FView>
      <FText h6 black heading bold className={classes.requiredPoints}>
        {requiredPoints === 0 ? '' : `${requiredPoints} POINTS`}
        {minimumSubTotal > 0 ? `MIN $${minimumSubTotal} SUBTOTAL` : ''}
        {requiredPoints === 0 && !minimumSubTotal ? 'FREE' : ''}
      </FText>
      <FView className={classes.name}>
        <FText h7 black bold heading textCenter>
          {name}
        </FText>
      </FView>
      <FView className={classes.redeemButton}>{RedeemRewardButtonElement}</FView>
    </div>
  )
}

const classes = {
  rewardContainer: css({
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  requiredPoints: css({
    position: 'absolute',
    top: 105,
  }),
  name: css({
    position: 'absolute',
    bottom: 125,
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: 40,
  }),
  meter: css({
    position: 'absolute',
    top: 20,
    left: 19,
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsText: css({
    position: 'absolute',
    top: 53,
    right: 15,
  }),
}
