import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

const REWARD_VIEW_WIDTH = 375
const REWARD_VIEW_HEIGHT = 550

export default function RewardItemView({ imageUrl }) {
  const { themeImages } = useContext(ThemeContext)
  return (
    <FView center className={classes.container} pointerEvents="none">
      {imageUrl ? (
        <img className={classes.image} src={imageUrl} alt="Reward" />
      ) : (
        <img className={classes.rewardDefault} src={themeImages.icon} alt="Reward" />
      )}
      <img className={classes.rewardBorder} src={themeImages.rewardBorder} alt="Reward Border" />
    </FView>
  )
}

const classes = {
  container: css({
    height: REWARD_VIEW_HEIGHT,
    width: REWARD_VIEW_WIDTH,
  }),
  image: css({
    position: 'relative',
    height: 182,
    width: 182,
    borderRadius: 100,
    marginTop: -10,
    marginLeft: 3,
    objectFit: 'cover',
  }),
  rewardDefault: css({
    position: 'relative',
    objectFit: 'contain',
    height: 182,
    width: 182,
    marginTop: -10,
    marginLeft: 3,
    zIndex: 1,
  }),
  rewardBorder: css({
    width: 271,
    heigth: 271,
    position: 'absolute',
    zIndex: 2,
  }),
}
