import React, { useContext } from 'react'
import { FView, FText } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

function SectionLabel({ label, color }) {
  const { themeColors } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()

  return (
    <FView>
      <FText h4 h2={isMinLG} backgroundText bold style={{ zIndex: 1 }}>
        {label}
      </FText>
      <FView
        mt={isMinLG ? -15 : -8}
        h={isMinLG ? 15 : 10}
        w={isMinLG ? '30rem' : '17rem'}
        bg={color || themeColors.primary}
      />
    </FView>
  )
}

function FeatureItem({ imgSrc, title, description, isMiddle }) {
  const isMinLG = useMediaMinLG()

  return (
    <FView w={325} maxWidth={'100%'} mh={isMiddle && isMinLG ? 25 : 0}>
      <img src={imgSrc} style={{ objectFit: 'contain', width: '100%' }} alt="featured item" />
      <FView size={'1.5rem'} />
      <FText backgroundText h7 bold textCenter>
        {title}
      </FText>
      <FView size={'1rem'} />

      <FText backgroundText h7 textCenter lineHeight={1.3}>
        {description}
      </FText>
      <FView size={'2.5rem'} />
    </FView>
  )
}

export default function HomeView({ locationId }) {
  const isMinLG = useMediaMinLG()
  const { Home, Components, themeColors, themeImages } = useContext(ThemeContext)
  return (
    <FView bg={themeColors.background} overflowX={'hidden'}>
      <FView style={{ background: `url(${themeImages.hugeBG}) center center / contain repeat-y` }}>
        <Components.PageHeader />
        <Home.Hero appDownloadLink={'https://onelink.foodly.tech/kovan'} title="Traditional Korean Cuisine" />
        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView mh={isMinLG ? 0 : '1.5rem'}>
          <FView selfCenter w={isMinLG ? 1024 : 345} maxWidth={'100%'}>
            <SectionLabel label={'Our Specialties'} color="#ED2A33" />
            <FView size={isMinLG ? '5rem' : '2.5rem'} />
            <img src={themeImages.specialty} alt="specialty" style={{ objectFit: 'contain', maxWidth: '100%' }} />
            <FView size={isMinLG ? '5rem' : '1.5rem'} />
            <FText backgroundText h7 h4={isMinLG} bold textCenter>
              Korean Cuisine
            </FText>
            <FView size={'1.5rem'} />
            <FText backgroundText h7 h5={isMinLG} textCenter lineHeight={1.3}>
              Our specialty dishes are inspired by traditional Korean cuisine. We use authentic ingredients, seasoning,
              and cooking methods to create flavorful dishes. Try one of our barbecue dishes, chef specialties or hot
              dishes, all served in a pure Korean spirit.
            </FText>
          </FView>
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView selfCenter w={isMinLG ? 1024 : 355} maxWidth={'100%'} ml={isMinLG ? 0 : 30}>
          <SectionLabel label={`Featured Items`} />
        </FView>
        <FView size={isMinLG ? '5rem' : '1.5rem'} />

        <FView selfCenter row={isMinLG}>
          <FeatureItem
            imgSrc={themeImages.feature1}
            title={'Stir-fried Kimchi Udon'}
            description={
              'A staple in Korean cuisine made with udon noodles and pork belly topped with fermented vegetables and seasonings.'
            }
          />
          <FeatureItem
            imgSrc={themeImages.feature2}
            title={'Pork Back Bone and Kimchi Hot Pot'}
            description={
              'A delicious hot pot made with marinated meat and fermented vegetables cooked in a spicy soup.'
            }
            isMiddle
          />
          <FeatureItem
            imgSrc={themeImages.feature3}
            title={'Beef Bulgogi'}
            description={
              'Another Korean classic made with thin slices of marinated beef grilled on a stove-top griddle.'
            }
          />
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView selfCenter w={1024} maxWidth={'100%'} ml={15}>
          <SectionLabel label={`Now Taking Online Orders`} color="#8BC542" />
        </FView>
        <FView size={isMinLG ? '5rem' : '1.5rem'} />
        <FView selfCenter>
          <FView w={1440} maxWidth={'100vw'}>
            <img src={themeImages.mobile} alt="mobile order" style={{ objectFit: 'contain', width: '100%' }} />
          </FView>
          <FText backgroundText h7 h5={isMinLG} textCenter lineHeight={1.3} bold>
            Order online and start earning points to redeem free items.
          </FText>
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView mh={15}>
          <FView selfCenter w={isMinLG ? 1024 : 345} maxWidth={'100%'}>
            <SectionLabel label={`About Us`} color="#21A8E0" />
            <FView size={isMinLG ? '5rem' : '1.5rem'} />
            <FView row={isMinLG} alignCenter>
              <img
                src={themeImages.aboutUs}
                style={{ objectFit: 'contain', maxHeight: '73vw', maxWidth: '100%' }}
                alt="about us"
              />
              <FView size={isMinLG ? 50 : 15} />
              <FText backgroundText h7 h5={isMinLG} textCenter={!isMinLG} lineHeight={1.3} style={{ maxWidth: 374 }}>
                We proudly serve only high quality ingredients prepared with great attention to details. Come visit us
                in beautiful Langley and experience traditional Korean food in a warm and welcoming atmosphere.
              </FText>
            </FView>
          </FView>
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
      </FView>
      <FView h={1} bg={themeColors.lightGrey} />
      <FView size={'5vw'} maxSize={44} />
      <Home.ContactUs
        appStoreUrl={'https://apps.apple.com/us/app/id1468823867'}
        googlePlayUrl="https://play.google.com/store/apps/details?id=tech.foodly.kovan"
      />
      <FView size={'12vw'} maxSize={72} />
      <Home.Footer />
    </FView>
  )
}
