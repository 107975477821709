export * from 'f-core/src/images'
export * from 'f-web-app/images'

export const logo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/logo.png'
export const icon = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/icon.png'
// HERO
export const heroBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kovan/heroBG.png'

export const specialty = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/specialty.png'

export const feature1 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/feature1.png'
export const feature2 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/feature2.png'
export const feature3 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/feature3.png'

export const hugeBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kovan/hugeBG.png'

export const mobile = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/mobile.png'

export const aboutUs = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/aboutUs.png'

// CONTACT US
export const contactUsMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kovan/contactUsMap.png'

// REWARD
export const pointsMeter = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kovan/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v2/kovan/rewardBG.png'
export const rewardBorder =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kovan/rewardBorder.png'
