import { COLOR as fColors } from 'f-web-app/styles'

export * from 'f-web/src/styles'

export const COLOR = {
  ...fColors,
  background: '#FDF6ED',
  backgroundText: '#010B19',
  primaryText: '#FFF',
  cardText: '#010B19',
  backgroundImageText: '#010B19',

  primary: 'rgba(246, 147, 34, 1)',
  primary75: 'rgba(246, 147, 34, .75)',
  primary50: 'rgba(246, 147, 34, .50)',
  primary25: 'rgba(246, 147, 34, .25)',
  primary10: 'rgba(246, 147, 34, .10)',

  secondary: 'rgba(232, 133, 20, 1)',
  secondary75: 'rgba(232, 133, 20, .75)',
  secondary50: 'rgba(232, 133, 20, .50)',
  secondary25: 'rgba(232, 133, 20, .25)',
  secondary10: 'rgba(232, 133, 20, .10)',
}

export const TEXT = {
  heading: {
    fontFamily: 'Lato',
  },
}
