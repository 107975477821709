import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function Hero({ appDownloadLink, title }) {
  const { Home, themeImages } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()
  const isMinMD = useMediaMinMD()
  return (
    <FView
      className={css(classes.container, {
        background: `url(${themeImages.heroBG}) no-repeat`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      })}
      mt={isMinMD ? 0 : 120}
      center={!isMinLG}
    >
      <FView alignCenter alignEnd={isMinLG} mt={isMinLG ? '20vh' : 0} mr={isMinLG ? '14.5%' : 0}>
        <FView maxWidth={'44rem'}>
          <FText h4 h2={isMinLG} black heading bold textCenter textRight={isMinLG}>
            {title}
          </FText>
        </FView>
        <FView h={'2.5rem'} />
        <FView row={isMinLG}>
          <Home.OrderNowButton />
        </FView>
      </FView>
    </FView>
  )
}

const classes = {
  container: css({
    alignSelf: 'center',
    minHeight: 280,
    height: '100vh',
    maxHeight: '66.7vw',
    width: '100%',
    // maxWidth: 1440,
    position: 'relative',
  }),
}
